import { render, staticRenderFns } from "./childrenage.vue?vue&type=template&id=2d0abf6f&"
import script from "./childrenage.vue?vue&type=script&lang=js&"
export * from "./childrenage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2d0abf6f')) {
      api.createRecord('2d0abf6f', component.options)
    } else {
      api.reload('2d0abf6f', component.options)
    }
    module.hot.accept("./childrenage.vue?vue&type=template&id=2d0abf6f&", function () {
      api.rerender('2d0abf6f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8MWMMCTx0n0ri/JS/comp/childrenage.vue"
export default component.exports